import * as buffer from 'buffer';
(window as any).Buffer = buffer.Buffer;

Buffer.prototype.readBigUint64LE = function(offset = 0) {
    let ret = 0n;
    for (let i = offset; i < offset+8; i++) {
        ret += BigInt(this[i]) << (8n*BigInt(i));
    }
    return ret;
}

Buffer.prototype.readBigUint64BE = function(offset = 0) {
  let ret = 0n;
  for (let i = offset; i < offset+8; i++) {
    ret += BigInt(this[i]) << (8n * (7n - BigInt(i)));
  }
  return ret;
}
